import React from "react"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

import { GatsbyProps } from "Src/global-types"

const BehaviourismEssay: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Behaviourism Essay" />
    <h1>Behaviourism Essay</h1>
    <p>
      For a module I created a simple site that could display the data that had
      been researched.
    </p>
    <p>
      Check it out in the iFrame or you can visit it on
      <a href="https://liammyles.github.io/past-static-projects/behaviourism/">
        Github Pages Behaviourism Essay
      </a>
    </p>
    <ResponsiveIFrame src="https://liammyles.github.io/past-static-projects/behaviourism/" />
  </StandardLayout>
)

export default BehaviourismEssay
